// Header.js
import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { Drawer, Box, List, Avatar, ListItem, ListItemIcon, ListItemText, IconButton, Toolbar, AppBar, Typography, Badge } from '@mui/material';
import MenuIcon from '@mui/icons-material/Menu';
import MailIcon from '@mui/icons-material/Mail';
import DashboardIcon from '@mui/icons-material/Dashboard';
import InfoIcon from '@mui/icons-material/Info';
import { styled } from '@mui/material/styles';
import Footer from './Footer';  // Import the footer component
import { NavLink, useLocation } from 'react-router-dom';
import { useAuthState } from 'react-firebase-hooks/auth'; // Only if using react-firebase-hooks
import { auth } from '../helpers/firebaseConfig';
import SettingsIcon from '@mui/icons-material/Settings';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import SearchIcon from '@mui/icons-material/Search';
import NotificationsIcon from '@mui/icons-material/Notifications';
import SupportIcon from '@mui/icons-material/Support';
import ContactMailIcon from '@mui/icons-material/ContactMail';
import HomeIcon from '@mui/icons-material/Home';
import WorkIcon from '@mui/icons-material/Work';
import GroupIcon from '@mui/icons-material/Group';
import { getIdToken } from '../services/firebaseServices'; // Adjust the path to where firebaseServices is defined

const drawerWidth = 240;

const DrawerHeader = styled('div')(({ theme }) => ({
    ...theme.mixins.toolbar,
}));

const navigationLinks = [
    { title: 'Home', icon: <HomeIcon />, path: '/' },
    { title: 'Projects', icon: <WorkIcon />, path: '/projects' },
    //{ title: 'Groups', icon: <GroupIcon />, path: '/groups' },
];

const systemnavigationLinks = [
    { title: 'About', icon: <InfoIcon />, path: '/about' },
    { title: 'Support', icon: <SupportIcon />, path: '/support' },
    { title: 'Contact', icon: <ContactMailIcon />, path: '/contact' },
];

const extraLinks = [
    { title: 'Profile', icon: <AccountCircleIcon />, path: '/profile' },
    { title: 'Settings', icon: <SettingsIcon />, path: '/settings' },
    { title: 'Search', icon: <SearchIcon />, path: '/search' },
    { title: 'Notifications', icon: <NotificationsIcon />, path: '/notifications' },
];

const Header = () => {
    const [mobileOpen, setMobileOpen] = useState(false);
    const [isAppBarVisible, setIsAppBarVisible] = useState(true);
    const location = useLocation(); // Use this to determine path
    const [user, loading, error] = useAuthState(auth); // Get user state
    
    const [notificationCount, setNotificationCount] = useState(0);

    const [notificationSent, setNotificationSent] = useState(false);


    
    const fetchNotificationCount = async () => {
        try {

            if(notificationSent)
                return

            setNotificationSent(true)
            
            const token = await getIdToken();
            const config = {
                method: 'get',
                url: 'https://api.blueprintportal.net/notifications/new/count',
                headers: { 'Authorization': `${token}` }
            };

            const response = await axios(config);
            
            setNotificationSent(false)

            //const response = await axios.get('https://api.blueprintportal.net/notifications/new/count');
            setNotificationCount(response.data.count);
        } catch (error) {
            setNotificationSent(false)
            console.error('Error fetching notification count:', error);
        }
    };


    
    useEffect(() => {
        fetchNotificationCount();
    }, [location]);
    

/*
    useEffect(() => {

        //const intervalId = setInterval(fetchNotificationCount, 1000);

        //return () => clearInterval(intervalId);
    }, []);*/

    const handleDrawerToggle = () => {
        setMobileOpen(!mobileOpen);
    };

    const drawerContent = (
        <Box display="flex" flexDirection="column" height="100%">
            <Box sx={{ width:"100%",borderBottom:"solid 1px rgba(0, 0, 0, 0.12)"}}>
                <Box  display="flex" sx={{alignItems: 'center',    margin: "10px auto;",}}>
                    
                    <img style={{width:"50px"}} src="/logo.png"/>
                    <Typography  style={{fontSize:"20px",marginTop:"4px"}} component="h4" variant="h4" sx={{color:"#034ead"}}>
                        Blueprint<strong>Portal</strong>
                    </Typography>
                </Box>
            </Box>



            <Box sx={{ p: 2, display: 'flex', alignItems: 'center', flexDirection: 'column' }}>
                <Avatar sx={{ width: 56, height: 56 }} src={user?.photoURL || "/static/images/avatar/1.jpg"} />
                <Typography variant="h6" noWrap>{user?.displayName || 'No Name'}</Typography>
                <Typography variant="caption" noWrap>{user?.email}</Typography>
            </Box>

            <Box sx={{ p: 2, display: 'flex', textAlign: "center" }}>
                {extraLinks.map((Link, index) => (
                    <Box sx={{ flexGrow: 1 }} key={index}>
                        <IconButton component={NavLink} to={Link.path} onClick={() => setMobileOpen(false)}>

                            {Link.title == "Notifications" ?
                                <Badge color="secondary" badgeContent={notificationCount}>{Link.icon}</Badge>
                                :
                                Link.icon
                            }
                        </IconButton>
                    </Box>
                ))}
            </Box>

            <List>
                {navigationLinks.map((link, index) => (
                    <ListItem button key={index} component={NavLink} to={link.path} onClick={() => setMobileOpen(false)}>
                        <ListItemIcon>{link.icon}</ListItemIcon>
                        <ListItemText primary={link.title} />
                    </ListItem>
                ))}

                <hr />

                {systemnavigationLinks.map((link, index) => (
                    <ListItem button key={index} component={NavLink} to={link.path} onClick={() => setMobileOpen(false)}>
                        <ListItemIcon>{link.icon}</ListItemIcon>
                        <ListItemText primary={link.title} />
                    </ListItem>
                ))}
            </List>
            <Footer />
        </Box>
    );

    return (
        <div>
            <AppBar position="relative" sx={{
                display: { xs: 'block', sm: 'none' }, background: "none", boxShadow: 'none', width: { sm: `calc(100% - ${drawerWidth}px)` }, ml: { sm: `${drawerWidth}px` }
            }}>
                <Toolbar>
                    <IconButton
                        color="inherit"
                        aria-label="open drawer"
                        edge="start"
                        onClick={handleDrawerToggle}
                        sx={{ color: "#034ead", mr: 2, display: { xs: 'block', sm: 'none' } }}
                    >
                        <MenuIcon />
                    </IconButton>
                </Toolbar>
            </AppBar>

            <Drawer
                variant="temporary"
                open={mobileOpen}
                onClose={handleDrawerToggle}
                ModalProps={{ keepMounted: true }} // Better open performance on mobile.
                sx={{
                    width: drawerWidth,
                    flexShrink: 0,
                    [`& .MuiDrawer-paper`]: { width: drawerWidth, boxSizing: 'border-box' },
                    display: 'flex',
                    flexDirection: 'column' // Ensures that the content inside is managed with flexbox
                }}
            >
                {drawerContent}
            </Drawer>
            <Drawer
                variant="permanent"
                sx={{
                    display: { xs: 'none', sm: 'block' },
                    '& .MuiDrawer-paper': { boxSizing: 'border-box', width: drawerWidth },
                }}
                open
            >
                {drawerContent}
            </Drawer>
        </div>
    );
};

export default Header;
