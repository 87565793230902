import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { useParams, useNavigate, useLocation, Outlet, Link } from 'react-router-dom';
import {
  Paper,
  InputBase,
  Tab,FormControl,InputLabel,Select,
  Tabs,Divider,
  Container, Typography, Button, CircularProgress, List, ListItem, ListItemText,
  ListItemSecondaryAction, IconButton, TextField, Dialog, DialogContent, DialogActions,
  DialogTitle, Box, Alert, Menu, MenuItem
} from '@mui/material';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import { getIdToken } from '../../services/firebaseServices';
import parse from 'html-react-parser';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import { useSearchParams } from "react-router-dom";
import {QRCodeSVG} from 'qrcode.react';



const validateEmails = (emails) => {
  const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
  for (let email of emails) {
    if (!emailPattern.test(email)) {
      return false;
    }
  }
  return true;
};


const ProjectDetail = () => {
  const { projectId } = useParams();
  const [queryParameters] = useSearchParams()

   
  const [openDialog, setOpenDialog] = useState(false);
  const [openLeaveDialog, setOpenLeaveDialog] = useState(false);
  const [openNDADialog, setOpenNDADialog] = useState(false);
  const [loadingSignedNDAs, setLoadingSignedNDAs] = useState(false);
  const [signedNDAs, setSignedNDAs] = useState([]);
  const [project, setProject] = useState(null);
  const [loading, setLoading] = useState(false);
  const [value, setValue] = useState(0);
  const [selectedNDA, setSelectedNDA] = useState(null);
  const [viewDialog, setViewDialog] = useState(false);
  const [viewLoading, setViewLoading] = useState(false);
  const [viewError, setViewError] = useState('');
  const [emailAddresses, setEmailAddresses] = useState('');
  const [emailError, setEmailError] = useState('');
  const [submitting, setSubmitting] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null); // State for menu anchor element
  
  
  const [shareLink, setShareLink] = useState('');  // State to store the share link
  const [loadingShareLink, setLoadingShareLink] = useState(false); // Loading state for fetching the share link
  const [shareLinkError, setShareLinkError] = useState(null); // Error state for fetching share link
const [expiryDate, setExpiryDate] = useState(null);  // State to store the expiry date


  const navigate = useNavigate();
  const location = useLocation();

  const tabsAdmin = [
    { label: "Overview", path: "" },
    { label: "Tasks", path: "/tasks" },
    { label: "Files", path: "/files" },
    { label: "Settings", path: "/settings" },
  ];
  
  const tabsViewer = [
    { label: "Overview", path: "" },
    { label: "Tasks", path: "/tasks" },
    { label: "Files", path: "/files" },
  ];

  const tabsLimited = [
    { label: "Overview", path: "" },
    { label: "Tasks", path: "/tasks" }
  ];

  const tabs = ["owner", "admin"].includes(project?.role_type) ? tabsAdmin :  
            (!project?.invited && ["viewer"].includes(project?.role_type)) ? tabsViewer : tabsLimited;

  const Admin = !project?.invited && (project?.role_type) && (["admin","owner"].includes(project?.role_type)) 


  useEffect(() => {
    fetchProjectDetails();
  }, [projectId]);

  useEffect(() => {
    const currentTabPath = location.pathname.replace(`/projects/${projectId}`, '');
    const tabIndex = tabs.findIndex(tab => tab.path === currentTabPath);
    if (tabIndex !== -1) {
      setValue(tabIndex);
    }
  }, [location.pathname, projectId, tabs]);


  
  const handleCopyLink = () => {
    navigator.clipboard.writeText(shareLink);
  };
  
  // Function to fetch the share link from the API
  const getShareLink = async () => {
    setLoadingShareLink(true);
    setShareLinkError(null);
    try {

      const token = await getIdToken();
      const config = {
        method: 'post',
        url: `https://api.blueprintportal.net/projects/${projectId}/link`,
        headers: { 'Authorization': `${token}` },
        //data: { emails }
      };

       const response = await axios(config);
      
      // console.log(response)

      if (response.data.key) 
      {
        const generatedLink = `https://blueprintportal.net/projects/${projectId}?shareKey=${response.data.key}`; // Construct URL
        setShareLink(generatedLink);
      }
      else
      {
        setShareLinkError('Failed to generate share link');
      }

    } catch (error) {
      console.error('Error fetching share link:', error);
      setShareLinkError('Error fetching share link');
    } finally {
      setLoadingShareLink(false);
    }
  };










  
  
  const AcceptInvite = async () => {
    setLoading(true);
    try {
      const token = await getIdToken();
      const config = {
        method: 'post',
        url: `https://api.blueprintportal.net/projects/${projectId}/accept`,
        headers: { 'Authorization': `${token}` }
      };

      const response = await axios(config);
      if(response.data.success)
      {
        fetchProjectDetails()
      }
      else
      {
        setLoading(false);
      }
    } catch (error) {
      console.error('Failed to fetch project details:', error);
      setLoading(false);
    }
  };





  const fetchProjectDetails = async () => {

    console.log(queryParameters.get("shareKey"))

    var url = `https://api.blueprintportal.net/projects/${projectId}`

    if(queryParameters.get("shareKey"))
    {
      url += `?shareKey=${queryParameters.get("shareKey")}`
    }
    setLoading(true);
    try {
      const token = await getIdToken();
      const config = {
        method: 'get',
        url: url,
        headers: { 'Authorization': `${token}` }
      };

      const response = await axios(config);
      setProject(response.data);
      setLoading(false);
    } catch (error) {
      console.error('Failed to fetch project details:', error);
      setLoading(false);
    }
  };



  
  const fetchSignedNda = async () => {
    setLoadingSignedNDAs(true);
    try {
      const token = await getIdToken();
      const config = {
        method: 'get',
        url: `https://api.blueprintportal.net/projects/${projectId}/nda/signed`,
        headers: { 'Authorization': `${token}` }
      };

      const response = await axios(config);
      setSignedNDAs(response.data);
      setLoadingSignedNDAs(false);
    } catch (error) {
      console.error('Failed to fetch project details:', error);
      setLoadingSignedNDAs(false);
    }
  };


  const handleChange = (event, newValue) => {
    setValue(newValue);
    navigate(`/projects/${projectId}${tabs[newValue].path}`);
  };

  const handleMenuOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  const handleLeaveProject = () => {
    setOpenLeaveDialog(true);
    handleMenuClose();
  };

  const handleNDAClick = () => {
    setOpenNDADialog(true);
    handleMenuClose();
    
    fetchSignedNda()
  };

  
  const handleViewDialogClose = () => {
    setViewDialog(false);
    setSelectedNDA(null);
    setViewError('');
};

  const handleView = async (id) => {
    setViewDialog(true);
    setViewLoading(true);
    setViewError('');
    try {
        const token = await getIdToken();
        const config = {
            method: 'get',
            url: `https://api.blueprintportal.net/projects/${projectId}/nda/${id}`,
            headers: { 'Authorization': `${token}` }
        };

        const response = await axios(config);
        setSelectedNDA(response.data);
        setViewLoading(false);
    } catch (err) {
        console.error('Error fetching NDA:', err);
        setViewError('Failed to load NDA');
        setViewLoading(false);
    }
};



  const handleLeaveConfirm = async () => {
    setLoading(true);
    // Add API call to leave the project here
    setOpenLeaveDialog(false);
    setLoading(false);
    navigate('/projects'); // Navigate back to the projects list
  };

  const handleInviteSubmit = async () => {
    const emails = emailAddresses.split(',').map(email => email.trim());
    if (emails.length === 0 || !validateEmails(emails)) {
      setEmailError('Please enter valid email addresses separated by commas.');
      return;
    }

    setSubmitting(true);

    try {
      const token = await getIdToken();
      const config = {
        method: 'post',
        url: `https://api.blueprintportal.net/projects/${projectId}/invite`,
        headers: { 'Authorization': `${token}` },
        data: { emails }
      };

      await axios(config);
      setOpenDialog(false);
      setEmailAddresses('');
      setEmailError('');
    } catch (error) {
      console.error('Failed to send invitations:', error);
      setEmailError('Failed to send invitations. Please try again.');
    } finally {
      setSubmitting(false);
    }
  };

  if (loading) {
    return (
      <Paper style={{ display: 'flex', justifyContent: 'center', padding: 20 }}>
        <CircularProgress />
      </Paper>
    );
  }

  if (!project) {
    return (
      <Typography variant="h6" style={{ padding: 20 }}>
        Project not found
      </Typography>
    );
  }


  if(project?.invited)
    return(
    <>
      <Paper style={{ padding: 20 }}>
        <Typography variant="h4" gutterBottom>
          {project.title}
        </Typography>
        
        <Box sx={{mt:"5px",mb:"5px"}}>To see more and to get updates, click the button below.</Box>

        <Button  variant="contained" onClick={AcceptInvite} >Join project</Button>

        <Divider/>
        <Box sx={{mt:"10px"}}>
            {parse(project.description)}

            </Box>

      </Paper>
    </>)








  const urlInvite = `${window.location.protocol}//${window.location.hostname}/join/project/${projectId}`;

  return (
    <>
      <Paper style={{ padding: 20 }}>
        <Box display="flex" justifyContent="space-between" alignItems="center">
          <Typography variant="h4" gutterBottom>
            {project.title}
          </Typography>
          <IconButton onClick={handleMenuOpen}>
            <MoreVertIcon />
          </IconButton>

          

        </Box>


        


        {
          !project?.invited && ["owner","admin","viewer"].includes("role_type" in project ? project.role_type : "") ? 
          
          
          <Button onClick={() => setOpenDialog(true)}>
            Invite
          </Button> : null
        }

        <Menu
          anchorEl={anchorEl}
          open={Boolean(anchorEl)}
          onClose={handleMenuClose}
        >

{
          !project?.invited && ["owner","admin","viewer"].includes("role_type" in project ? project.role_type : "") ? 
          
          
          <MenuItem onClick={() => setOpenDialog(true)}>
            Invite 
          </MenuItem> : null
        }



          {project.nda_id || project.user_nda ? (
            <MenuItem onClick={handleNDAClick}>NDA</MenuItem>
          ) : null}
          <MenuItem onClick={handleLeaveProject}>Leave Project</MenuItem>
        </Menu>

        <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
          <Tabs value={value} allowScrollButtonsMobile variant="scrollable" scrollButtons="auto" onChange={handleChange} aria-label="project details tabs">
            {tabs.map((tab, index) => (
              <Tab key={index} label={tab.label} component={Link} to={`/projects/${projectId}${tab.path}`} />
            ))}
          </Tabs>
        </Box>
        <Outlet context={project} />
      </Paper>

      <Dialog open={openDialog} onClose={() => setOpenDialog(false)} aria-labelledby="form-dialog-title">
        <DialogTitle id="form-dialog-title">Invite</DialogTitle>
        <DialogContent>

  
        {!shareLink && (

        <>


             {/*} <TextField
                fullWidth
                label="Expiry Date (optional)"
                type="date"
                value={expiryDate || ''}
                onChange={(e) => setExpiryDate(e.target.value)}
                sx={{ mb: 2 }}
                InputLabelProps={{ shrink: true }}
              />*/}

              
              <Button
                variant="contained"
                color="primary"
                onClick={getShareLink}
                disabled={loadingShareLink}
                sx={{ mb: 2 }}
              >
                {loadingShareLink ? 'Generating...' : 'Get Share Link/QR code'}
              </Button>

          </>

        )}

          {/* Display the share link or error */}
          {shareLink && (
            <>

<Box sx={{  margin: "auto;", alignItems: 'center', mb: 2 }}>
<QRCodeSVG style={{    margin: "auto;"}} value={shareLink} />
</Box>


            <Box sx={{ display: 'flex', alignItems: 'center', mb: 2 }}>
              <TextField
                fullWidth
                label="Share Link"
                value={shareLink}
                disabled
                sx={{ mr: 2 }}
              />
              <IconButton onClick={handleCopyLink}>
                <ContentCopyIcon />
              </IconButton>
            </Box>
            <Box>This link can only be used with 1 user.</Box>
            </>
          )}
          {shareLinkError && (
            <Alert severity="error" sx={{ mb: 2 }}>{shareLinkError}</Alert>
          )}

          {/*}
          <Typography variant="h6" gutterBottom>
            Invite Link
          </Typography>


          <Paper component="form" sx={{ p: '2px 4px', display: 'flex', alignItems: 'center', mb: 2 }}>
            <InputBase sx={{ ml: 1, flex: 1 }} value={urlInvite} inputProps={{ 'aria-label': 'invite link' }} />
            <Button onClick={() => navigator.clipboard.writeText(urlInvite)} type="button" sx={{ p: '10px' }} aria-label="copy">
              Copy
            </Button>
          </Paper>
        */}





          <Typography variant="h6" gutterBottom>
            Invite by Email
          </Typography>
          <TextField
            fullWidth
            variant="outlined"
            label="Email Addresses"
            placeholder="Enter email addresses separated by commas"
            value={emailAddresses}
            onChange={(e) => setEmailAddresses(e.target.value)}
            error={Boolean(emailError)}
            helperText={emailError}
          />
          {emailError && <Alert severity="error">{emailError}</Alert>}
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setOpenDialog(false)} color="primary">
            Close
          </Button>
          <Button onClick={handleInviteSubmit} color="primary" disabled={submitting}>
            {submitting ? <CircularProgress size={24} /> : 'Submit'}
          </Button>
        </DialogActions>
      </Dialog>

      <Dialog open={openLeaveDialog} onClose={() => setOpenLeaveDialog(false)} aria-labelledby="leave-dialog-title">
        <DialogTitle id="leave-dialog-title">Leave Project</DialogTitle>
        <DialogContent>
          <Typography>Are you sure you want to leave this project?</Typography>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setOpenLeaveDialog(false)} color="primary">
            Cancel
          </Button>
          <Button onClick={handleLeaveConfirm} color="primary">
            Leave
          </Button>
        </DialogActions>
      </Dialog>

      <Dialog open={openNDADialog} onClose={() => setOpenNDADialog(false)} aria-labelledby="nda-dialog-title">
        <DialogTitle id="nda-dialog-title">NDA Details</DialogTitle>
        <DialogContent>
          
          {loadingSignedNDAs ? <CircularProgress /> : null }



              
              <List>
              {signedNDAs && signedNDAs.map((task) => (
                  <ListItem sx={{background:task.project?"#eee":"none"}} key={task.id} divider onClick={() => handleView(task.id)}>
                      <Box>
                          <Typography variant="h6">{task.created}</Typography>
                          <Typography variant="subtitle1" sx={{fontSize:"12px"}}>{task.uuid}</Typography>
                      </Box>
                  </ListItem>
              ))}
          </List>
        
        



        </DialogContent>
        <DialogActions>
          <Button onClick={() => setOpenNDADialog(false)} color="primary">
            Close
          </Button>
        </DialogActions>
      </Dialog>





      
            {/* View NDA Dialog */}
            <Dialog open={viewDialog} onClose={handleViewDialogClose}>
                <DialogTitle>View NDA</DialogTitle>
                <DialogContent>
                    
                    {viewLoading ? (
                        <CircularProgress />
                    ) : viewError ? (
                        <Typography color="error">{viewError}</Typography>
                    ) : (
                        selectedNDA && (
                            <Box>
                                {selectedNDA.project ? <Typography variant="h6">Used for projects</Typography> : null }
                                <Typography variant="h6">{selectedNDA.created}</Typography>
                                <Typography variant="subtitle1" sx={{fontSize:"12px"}}>{selectedNDA.uuid}</Typography>
                                {parse(selectedNDA.contains)}
                            </Box>
                        )
                    )}
                </DialogContent>
                <DialogActions>

                
                <Button onClick={handleViewDialogClose} color="primary">
                    Close
                </Button>
                </DialogActions>
            </Dialog>
    </>
  );
};

export default ProjectDetail;
