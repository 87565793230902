
/*// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import { getAuth } from "firebase/auth";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries
*/

import firebase from 'firebase/compat/app'
import 'firebase/compat/firestore'
import 'firebase/compat/auth'
import 'firebase/compat/analytics'
import 'firebase/compat/storage'
import { initializeApp } from 'firebase/app';



// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyDpUS6EKsqPWP0H4LAL7z50mRGeH9J2Qd4",
  authDomain: "blueprint-protal.firebaseapp.com",
  projectId: "blueprint-protal",
  storageBucket: "blueprint-protal.appspot.com",
  messagingSenderId: "430212112659",
  appId: "1:430212112659:web:93d87075eede627d137ae6",
  measurementId: "G-1EFWERS41N"
};

// Initialize Firebase
/*const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);
const auth = getAuth(app);
*/

firebase.initializeApp(firebaseConfig);

export default firebase;
export const db = firebase.firestore();
export const storage = firebase.storage();
export const auth = firebase.auth();


/*// Import the functions you need from the SDKs you need
import firebase from 'firebase/compat/app'
//import 'firebase/compat/firestore'
import 'firebase/compat/auth'
import 'firebase/compat/analytics'
//import 'firebase/compat/storage'
//import { getAnalytics } from "firebase/analytics";

import { initializeApp } from "firebase/app"



// Your web app's Firebase configuration
const firebaseConfig = {
  apiKey: "AIzaSyDpUS6EKsqPWP0H4LAL7z50mRGeH9J2Qd4",
  authDomain: "blueprint-protal.firebaseapp.com",
  projectId: "blueprint-protal",
  storageBucket: "blueprint-protal.appspot.com",
  messagingSenderId: "430212112659",
  appId: "1:430212112659:web:93d87075eede627d137ae6",
  measurementId: "G-1EFWERS41N"
};

// Initialize Firebase
//firebase.initializeApp(firebaseConfig);

// Optionally enable analytics and performance monitoring
// import 'firebase/analytics';
// import 'firebase/performance';

// Export Firebase authentication, Firestore database, and Firebase storage
const app = initializeApp(firebaseConfig);
//const analytics = getAnalytics(app);
const auth = firebase.auth(app);
//const db = firebase.firestore();
//const storage = firebase.storage();
*/
//export { auth,/* db, storage,*/ getAuth as default };
