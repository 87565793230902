import React from 'react'
import Box from '@mui/material/Box';

const View = () => {

    return (
            <Box className="View404">
                <h1><strong>404.</strong> Could not find page.</h1>
                <p>We could not find the page you were looking for.</p>
            </Box>
        )
    
}
export default View
