import React, { useState } from 'react';
import { GoogleAuthProvider, signInWithPopup, signInWithEmailAndPassword, createUserWithEmailAndPassword, sendPasswordResetEmail } from 'firebase/auth';
import { Button,Alert, TextField, Box, Typography, Container } from '@mui/material';
import GoogleIcon from '@mui/icons-material/Google';
import { NavLink, useLocation } from 'react-router-dom';
import {  useNavigate  } from 'react-router-dom';
import {  auth  } from '../helpers/firebaseConfig';
import 'firebase/compat/auth'
import firebase from 'firebase/compat/app'



const LoginPage = () => {
    const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [passwordConfirm, setPasswordConfirm] = useState('');
  const [name, setName] = useState('');
  const [isSignup, setIsSignup] = useState(false);
  const [isForgotPassword, setIsForgotPassword] = useState(false);
  const [error, setError] = useState(null);



  const location = useLocation();
  const navigate = useNavigate();

  //const auth = getAuth();

    

    const handleError = (error) => {
        //console.log(error)
        switch (error.code) {
            case 'auth/email-already-in-use':
                setError('Email already in use.');
                break;
            case 'auth/user-not-found':
                setError('Email not added.');
                break;
            case 'auth/wrong-password':
                setError('Wrong password or email.');
                break;
            default:
                setError('Could not log in, please try again.');
                break;
        }
        console.error('Authentication error:', error.message);
    };

    
  const handleLogin = async (e) => {
    e.preventDefault();
    if (isSignup && password !== passwordConfirm) {
      setError("Passwords do not match!");
      return;
    }

    try {
      if (isSignup) {
        await createUserWithEmailAndPassword(auth, email, password);
        console.log("Account created successfully!");
      } else {
        await signInWithEmailAndPassword(auth, email, password);
        console.log("Logged in successfully!");
        if (location.pathname === '/login') {
            navigate('/');
          }
      }
    } catch (error) {
      setError("Authentication error: " + error.message);
      console.error('Authentication error:', error);
    }
  };




    
  const handleGoogleLogin = async () => {
    const provider = new GoogleAuthProvider();

    try {
      const result = await signInWithPopup(auth, provider);
      const user = result.user;
      console.log("Logged in user:", user);
    } catch (error) {
      setError("Google login error: " + error.message);
      console.error('Google login error:', error);
    }
  };

  const handleForgotPassword = async () => {
    if (!email) {
      alert("Please enter your email address to reset your password.");
      return;
    }
    try {
      await sendPasswordResetEmail(auth, email);
      alert("Password reset email sent!");
    } catch (error) {
      console.error("Failed to send password reset email:", error);
    }
  };

    return (
        <Container component="main" maxWidth="xs">
            <Box
                sx={{
                    marginTop: 8,
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                }}
            >

                <img style={{width:"50%",maxWidth:"130px"}} src="/logo.png"/>
                <Typography component="h4" variant="h4" sx={{color:"#034ead"}}>
                    Blueprint<strong>Portal</strong>
                </Typography>
                {/*<Typography component="h3" variant="h5">
                    {isSignup ? 'Sign Up' : isForgotPassword ? 'Reset Password' : 'Sign In'}
                </Typography>*/}
                <Box component="form" onSubmit={isForgotPassword ? handleForgotPassword : handleLogin} noValidate sx={{ mt: 1 }}>
                    <TextField
                        variant="outlined"
                        margin="normal"
                        required
                        fullWidth
                        id="email"
                        label="Email Address"
                        name="email"
                        autoComplete="email"
                        autoFocus
                        value={email}
                        onChange={(e) => {setEmail(e.target.value);setError(null)}}
                    />
                    {!isForgotPassword && (
                        <TextField
                            variant="outlined"
                            margin="normal"
                            required
                            fullWidth
                            name="password"
                            label="Password"
                            type="password"
                            id="password"
                            autoComplete="current-password"
                            value={password}
                            onChange={(e) => {setPassword(e.target.value);setError(null)}}
                        />
                    )}
                    {isSignup && (
                        <>
                            <TextField
                                variant="outlined"
                                margin="normal"
                                required
                                fullWidth
                                name="passwordConfirm"
                                label="Confirm Password"
                                type="password"
                                id="passwordConfirm"
                                value={passwordConfirm}
                                onChange={(e) => {setPasswordConfirm(e.target.value);setError(null)}}
                            />
                            <TextField
                                variant="outlined"
                                margin="normal"
                                required
                                fullWidth
                                name="name"
                                label="Full Name"
                                type="text"
                                id="name"
                                autoComplete="name"
                                value={name}
                                onChange={(e) => {setName(e.target.value);setError(null)}}
                            />
                        </>
                    )}

{error  ?
<Alert severity="error">{error}</Alert>
:null}


                    <Button
                        type="submit"
                        fullWidth
                        variant="contained"
                        sx={{ mt: 3, mb: 2 }}
                    >
                        {isSignup ? 'Sign Up' : isForgotPassword ? 'Send Reset Email' : 'Sign In'}
                    </Button>

                    
<Button
                        fullWidth
                        variant="outlined"
                        startIcon={<GoogleIcon />}
                        sx={{ mt: 3, mb: 2 }}
                        onClick={handleGoogleLogin}
                    >
                        Sign in with Google
                    </Button>

                    {!isSignup && !isForgotPassword && (
                        <Button
                            fullWidth
                            sx={{ mt: 2 }}
                            onClick={() => {setIsForgotPassword(true);setError(null)}}
                        >
                            Forgot Password?
                        </Button>
                    )}
                    {!isForgotPassword && (
                        <Button
                            fullWidth
                            sx={{ mt: 2 }}
                            onClick={() => { setIsSignup(!isSignup); setIsForgotPassword(false); ;setError(null)}}
                        >
                            {isSignup ? 'Already have an account? Sign in' : "Don't have an account? Sign up"}
                        </Button>
                    )}
                    {isForgotPassword && (
                        <Button
                            fullWidth
                            sx={{ mt: 2 }}
                            onClick={() => {setIsForgotPassword(false);setError(null)}}
                        >
                            Back to sign in.
                        </Button>
                    )}





                </Box>

                <Box>
                <NavLink  to="/terms">terms</NavLink> • <NavLink  to="/privacy">privacy</NavLink> 

                </Box>
            </Box>
        </Container>
    );
};

export default LoginPage;
