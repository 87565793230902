import React,{useEffect,useState} from 'react';
import { BrowserRouter, Route, Routes, Navigate  } from 'react-router-dom';


import Login from './views-login/Login';
import Loginindex from './views-login/Index';
import Logout from './views-dashboard/Auth/Logout';
import Page404 from './views/Page404';
import Loading from './components/Loading';


import LoginHeader from './components-login/Header';
import LoginFooter from './components-login/Footer';


import Header from './components-dashboard/Header';
import Footer from './components-dashboard/Footer';

import Home from './views-dashboard/Home';

//import Dashboard from './views-dashboard/Dashboard';

import ProjectList from './views-dashboard/Projects/List';
import ProjectAdd from './views-dashboard/Projects/Add';
import ProjectDetails from './views-dashboard/Projects/Details';
import ProjectOverview from './views-dashboard/Projects/Components/Overview';
import ProjectTasks from './views-dashboard/Projects/Components/Tasks';
import ProjectFiles from './views-dashboard/Projects/Components/Files';
import ProjectWhiteboards from './views-dashboard/Projects/Components/Whiteboards';
import ProjectTimeline from './views-dashboard/Projects/Components/Timeline';
import ProjectSettings from './views-dashboard/Projects/Components/Settings';

import About from './views/About';
import Support from './views/Support';
import Contact from './views/Contact';
import Privacy from './views/Privacy';
import Terms from './views/Terms';

import Search from './views-dashboard/Search';

//import { useAuth } from './contexts/AuthContext'; // Adjust this import based on your actual file structure
import Box from '@mui/material/Box';




import GroupList from './views-dashboard/Groups/List';
import GroupAdd from './views-dashboard/Groups/Add';
import GroupOverview from './views-dashboard/Groups/Components/GroupOverview';
import GroupDetails from './views-dashboard/Groups/Details';
import GroupProfiles from './views-dashboard/Groups/Components/GroupProfiles';
import GroupProjects from './views-dashboard/Groups/Components/GroupProjects';
import GroupSettings from './views-dashboard/Groups/Components/GroupSettings';

import JoinProject from './views-dashboard/Join/Project';
import JoinGroup from './views-dashboard/Join/Group';
import JoinJoin from './views-dashboard/Join/Join';



import Profile from './views-dashboard/Profile/Profile';
import ProfileFriends from './views-dashboard/Profile/Friends';
import ProfileIdProjects from './views-dashboard/Profile/Projects';

import Settings from './views-dashboard/Profile/Settings';
import Notifications from './views-dashboard/Profile/Notifications';

import { auth } from './helpers/firebaseConfig';


const drawerWidth = 240;

function App() {
/*
  const [loading, setLoading] = useState(true);

  const { currentUser } =  auth // useAuth(); // Destructure the loading state from useAuth

  
  useEffect(() => {
        setLoading(false);

    // Cleanup subscription on unmount
}, [auth,currentUser]);*/



const [user, setUser] = useState(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const unsubscribe = auth.onAuthStateChanged((authUser) => {
      if (authUser) {
        setUser(authUser);
      } else {
        setUser(null);
      }
      setLoading(false);
    });

    return () => unsubscribe();
  }, []);




if(loading)
return( <Loading/> )


  if(!user)
  {
    return(

    <BrowserRouter>
    <LoginHeader/>
      <Routes>
      
      <Route path="/" element={<Loginindex />} />

        <Route path="/terms" element={<Terms />} />
        <Route path="/privacy" element={<Privacy />} />
        <Route path="/*" element={<Login />} />
        
      </Routes>
    <LoginFooter/>
    </BrowserRouter>
    )
  }




  return (
    <BrowserRouter>
    <Header />
    <Box sx={{  width: { sm: `calc(100% - ${drawerWidth}px)` }, ml: { sm: `${drawerWidth}px` } }}>
    <Box component="main" sx={{  p:3 }}>

      <Routes>
      <Route path="/" element={<Home />} />
      <Route path="/logout" element={<Logout />} />
      <Route path="/login"element={<Navigate to="/" replace />} />

        {/*<Route path="/dashboard" element={<Dashboard />} />*/}



        <Route path="/projects" element={<ProjectList />} />
        <Route path="/project/add" element={<ProjectAdd />} />
        <Route path="/projects/:projectId/" element={<ProjectDetails />} >
          <Route index element={<ProjectOverview />} />
          <Route path="tasks" element={<ProjectTasks />} />
          <Route path="timeline" element={<ProjectTimeline />} />
          <Route path="whiteboards" element={<ProjectWhiteboards />} />
          <Route path="whiteboards/:fileId" element={<ProjectWhiteboards />} />
          <Route path="files" element={<ProjectFiles />} />
          <Route path="settings" element={<ProjectSettings />} />
        </Route >






        <Route path="/groups" element={<GroupList />} />
        <Route path="/groups/add" element={<GroupAdd />} />
        <Route path="/groups/:groupId" element={<GroupDetails />} >
          <Route index element={<GroupOverview />} /* projects  */ />
          <Route path="profiles" element={<GroupProfiles />} />
          <Route path="projects" element={<GroupProjects/>} />
          <Route path="Settings" element={<GroupSettings />} />
        </Route>




        {/*<Route path="/join" element={<ProjectDetails />} />*/}
        <Route path="/join/project/:projectId" element={<JoinProject />} />
        <Route path="/join/group/:groupId" element={<JoinGroup />} />
        <Route path="/join/:joinId" element={<JoinJoin />} />



        <Route path="/settings" element={<Settings />} />
        <Route path="/notifications" element={<Notifications />} />
        
        <Route path="/profile/" element={<Profile />} />
        {/*<Route path="/profile/friends" element={<ProfileFriends />} />}*/}
        <Route path="/profile/:userId" element={<Profile/>} />
        {/*<Route path="/profile/:userId/projects" element={<ProfileIdProjects />} />*/}


        <Route path="/search" element={<Search />} />



        <Route path="/about" element={<About />} />
        <Route path="/support" element={<Support />} />
        <Route path="/contact" element={<Contact />} />
        
        <Route path="/terms" element={<Terms />} />
        <Route path="/privacy" element={<Privacy />} />


        <Route path="/*" element={<Page404 />} />
      </Routes>
      </Box>
      </Box>
    </BrowserRouter>
  );
}

export default App;
